@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-inter: 'Inter', sans-serif;
  --font-codecpro: 'Codec Pro Regular', sans-serif;
  --font-codecpro-light: 'Codec Pro Light', sans-serif;
  --font-codecpro-bold: 'Codec Pro Bold', sans-serif;
}

body {
  width: 100%;
  background-color: #f5f5f9;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body * {
  font-family: 'Inter', sans-serif;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
