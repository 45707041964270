.attention {
  @apply absolute top-1/2 right-4 bg-contain bg-no-repeat h-[29px] w-[29px];
  transform: translateY(-50%);
}

.attention > div {
  padding: 10px 12px;
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  top: -48px;
  left: -183px;
  min-width: 215px;
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.08);
  display: none;
}

.attention:hover > div {
  display: block;
}
