.smallBlue {
  border-radius: 15px;
  border: 1px solid #ebeff6;
}

.shadow {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
