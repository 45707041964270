.select {
  min-width: 180px;
  padding: 15px 16px 15px 4px;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 24px;
  background: #f5f5f5;
  color: '#8A95B1';
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

.select:disabled {
  color: '#555';
}

.options {
  margin-top: 5px;
  padding: 10px;
  border-radius: 0.5rem;
  user-select: none;
}

.option {
  padding: 10px;
  background: #fff;
  color: #8e8e8e;
  font-weight: 500;
  letter-spacing: -0.52px;
}

.optionsDiv {
  @apply absolute left-0 top-[36px] flex flex-col p-[10px] bg-white z-50;
  max-height: 10rem;
  overflow-y: auto;
  border-radius: 9px;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.08);
}

.optionDiv {
  @apply transition-all cursor-pointer font-medium bg-white text-[#8e8e8e];
  width: calc(100% - 20px);
}

.optionDiv:hover,
.optionDiv:active {
  /* padding: 5px 6px; */
  border-radius: 5px;
  background: #eff3ff;
  color: #0446f1;
}

.input::placeholder {
  font-weight: 500;
  color: #8a95b1;
}

.icon {
  position: absolute;
  left: 11px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

.iconEnd {
  right: 11px;
  left: auto;
}
