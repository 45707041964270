.button:focus,
.button:hover {
  transition: all 0.25s;
  box-shadow: 4px 4px 4px grey;
  transform: translate3d(1px, 0, 16px);
}

.button:disabled:hover {
  box-shadow: none;
  transform: none;
}

.buttonRegisterCompany {
  /* color: brown; */
}