.wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
}

.container {
  @apply absolute lg:left-[33%] left-[50%] top-1/2 w-fit h-fit transform -translate-y-1/2 flex flex-col gap-4;
  padding: 20px 24px 24px 24px;
  border-radius: 24px;
  min-width: 328px;
  max-height: 95vh;
  overflow-y: auto;
  align-items: center;
}

.container::-webkit-scrollbar {
  width: 4px;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 3px;
}

.container div {
  /* min-width: 400px; */
}

.input,
.inputU {
  @apply px-3 py-2 w-full;
  border-radius: 24px;
  background: #fff !important;
  outline-color: #989fad;
  border: 1px solid transparent;
  transition: all 0.25s;
}

.input:invalid,
.inputU:invalid,
.inputUInvalid {
  color: #ff4747;
  border: 1px solid #ff4747;
  outline: none;
}

.inputU {
  background-color: #f6f7f9;
}

.input::placeholder,
.inputU::placeholder {
  color: #989fad;
  font-weight: 100;
}

.sendBtn,
.btn {
  width: 50%;
  transition: all 0.25s;
}

.btn {
  max-width: 170px;
}

.btnBack {
  max-width: 113px;
  background: #f3f6fe !important;
  color: #0446f1;
  transition: all 0.25s;
}

.sendBtn:disabled,
.btn:disabled {
  background-color: lightgrey;
  color: grey;
  cursor: not-allowed;
}

.btn:disabled {
  color: rgba(255, 255, 255, 0.8);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #5a83ec;
}


.onbouarding_ein {
  /* color: #ff4747; */
}

.onbouarding_company_name {
  /* color: #ff4747; */
}