.container {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 52px;
  padding: 4px;
  border-radius: 72px;
  background: #fff;
}

.container * {
  user-select: none;
  transition: all 0.25s;
}

.container .children {
  display: flex;
  flex: auto;
}

.container .children div {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.selector {
  position: absolute;
  border-radius: 25px;
  background: #0446f1;
  top: 0;
  bottom: 0;
}

.children {
  display: flex;
  z-index: 1;
}
