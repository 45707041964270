.wrapper {
  @apply p-6 inline-flex flex-col items-start gap-2;
  width: fit-content;
  min-height: 100dvh;
  flex-shrink: 0;
  border-right: 1px solid #ebeff6;
  background: #fff;
  user-select: none;
}

.menuItem {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  color: #989fad;
  transition: all 0.2s;
}

.selected {
  background: #0446f1;
  color: #fff;
}

.selected img {
  fill: red;
}
