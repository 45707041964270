.moreOptions {
  position: relative;
}

.moreOptions + div {
  z-index: 99;
  position: absolute;
  bottom: -116px;
  right: 0;
  transition: all 0.3s;

  flex-direction: column;

  padding: 10px 5px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.08);
  user-select: none;
}

.moreOptions + div div {
  width: 9rem;
  padding: 5px 6px;
}

.moreOptions + div div:hover {
  background: #eff3ff;
  color: #0446f1;
  cursor: pointer;
}
