.MenuItem {
  padding: 12px 16px;
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  align-self: stretch;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
}

.MenuItem:hover {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  transform: translate3d(1px, 0, 16px);
}
