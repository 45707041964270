/* .modal {
  width: 95% !important;
} */

.wrapper > div > div {
  background-color: #f6f7f9 !important;
}

.card {
  @apply border;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 25px 20px;
  /* min-height: 366px; */
  border-radius: 24px;
  background: #fff;
}

.card div:first-child {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
}

.cardDesc {
  font-size: 15px;
}

.active {
  background-color: #3f74ff;
  color: #fff;
}

.input,
.input::placeholder {
  font-size: 24px;
  text-align: center;
}

.input2 {
  width: 50%;
}

.input2,
.input2::placeholder {
  margin: 0 auto;
  font-size: 16px;
  border-radius: 8px;
  padding: 4px 8px;
}
