@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Codec Pro Bold';
  src: url('//fonts/CodecPro-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Codec Pro Light';
  src: url('//fonts/CodecPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Codec Pro Regular';
  src: url('//fonts/CodecPro-News.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

// body {
//   font-family: 'Codec Pro Regular', sans-serif;
// }
