@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color of the track */
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #999; /* Set the color of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777; /* Set the color of the thumb on hover */
}
