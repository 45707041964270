.blueButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 8px;
  border-radius: 16px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.52px;
  color: #0446f1;
}

.blueButton:disabled {
  opacity: 50%;
}

.smallBlue {
  padding: 4px 8px;
  border-radius: 48px;
}

.mediumBlue {
  padding: 12px 24px;
  background: #f6f7fc;
}

.bigBlue {
  flex: 1;
  padding: 12px 8px;
  background: #0446f1;
  color: #fff;
}

.lightBlue {
  width: 100%;
  border-radius: 48px;
  background: #f6f7fc;
  color: #0446f1;
}
